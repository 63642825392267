import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalController, Platform } from '@ionic/angular';
import { confirmAlert, savePdf } from 'src/app/core/utils/utils';
import { NewsletterService } from '../../../services/newsletter.service';
import { openBrowser } from 'src/app/core/utils/utils';
import { CommonsService } from 'src/app/core/services/commons/commons.service';

@Component({
    selector: 'app-newsletter-modal',
    templateUrl: './newsletter-modal.component.html',
    styleUrls: ['./newsletter-modal.component.css'],
    standalone: false
})
export class NewsletterModalComponent implements OnInit {
  disabledAcknowdge: boolean;

  constructor(
    private newsletterService: NewsletterService,
    public dialogNewsletterView: MatDialogRef<NewsletterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public newsletter: any,
    private commonsService: CommonsService,
    private platform: Platform,
  ) { }

  ngOnInit() {
    if (this.newsletter.acknowledge) {
      this.disabledAcknowdge = true;
    }
  }

  acknowledge() {
    this.disabledAcknowdge = true;
    this.commonsService.registryEvent({ category: 'newsletter', event: 'acknowledge_newsletter'});
    this.newsletterService.setAcknowledge(this.newsletter.id).subscribe({
      next: resp => {
        this.disabledAcknowdge = false;
        this.dialogNewsletterView.close(true);
      },
      error: err => {
        console.log(err);
        this.disabledAcknowdge = false;
      }
    });
  }

  closeClick(): void {
    this.dialogNewsletterView.close(false);
  }

  download():void{

    let name = this.newsletter.title;
      if (!this.platform.is('hybrid')) {
        // save doc web
        window.open(this.newsletter.imgUrl, '_blank');

      } else {
        // save doc app mobile iOS | android
        let ref = this;
        let reader = new FileReader();
        reader.readAsDataURL(this.newsletter.imgUrl);
        reader.onloadend = function () {
          let base64 = reader.result.toString();
          ref.savePdfMobile(base64, name);
        }
      }
    }
  
    savePdfMobile(base64: string, name: string) {
      savePdf(base64, name);
    }

  

  openExternal(externalUrl: string) {
    openBrowser(externalUrl);
  }
}
