import { Component, OnInit, Inject } from '@angular/core';
import { PilotConstants } from '../../../commons/pilot-constants';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IndexeddbService } from '../../../services/indexeddb.service';
import { Profile } from '../../../domain/profile';
import { PilotProfileRequest } from 'src/app/modules/pilot/domain/pilot-profile-request';
import { finalize } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user/user.service';
import { ProfileService } from 'src/app/modules/pilot/services/profile.service';

@Component({
    selector: 'app-profile-modal',
    templateUrl: './profile-modal.component.html',
    styleUrls: ['./profile-modal.component.css'],
    standalone: false
})
export class ProfileModalComponent implements OnInit {
  ownerList = this.constants.OWNER_LIST;
  fleetList = this.constants.FLEET_LIST;
  profileFormGroup: UntypedFormGroup;
  profile: Profile;
  route;
  progressBar: number = 0;
  // showNav: boolean = false;
  loadData:boolean = false;
  profileData: PilotProfileRequest;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<ProfileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private constants: PilotConstants,
    private pilotProfileService: ProfileService,
    private indexeddbService: IndexeddbService, 
    private _user: UserService
  ) {
    this.title = data ? data.message : null;
  }

  ngOnInit() {
    this.getData();
    this.initModifyProfileForm();
  }

  initModifyProfileForm() {
    this.profileFormGroup = new UntypedFormGroup({
      fleet: new UntypedFormControl("", [Validators.required]),
      operator: new UntypedFormControl("", [Validators.required])
    });
  }

  setProfile() {
    this.loadData = true; 
    this.profile = new Profile();
    this.profile.fleet = this.profileFormGroup.controls.fleet.value;
    this.profile.operator = this.profileFormGroup.controls.operator.value;
    this.profileData = new PilotProfileRequest();
    this.profileData.fleet = this.profileFormGroup.controls.fleet.value;
    this.profileData.owner = this.profileFormGroup.controls.operator.value;
    this.profileData.terms = true;

    let user = this._user.getUserValue;
    let { fleet, operator } = this.profile;
    this.pilotProfileService.savePilotProfile(this.profileData)
    .pipe(finalize(() => this.closeClick(true)))
    .subscribe({
      next: (resp) => {
        user.profile =  { ...user.profile, fleet, operator };
        this._user.updateLocalUser(user);
      }, 
      error: error => {
        if (error =="502") {
          user.profile =  { ...user.profile, fleet, operator };
          this._user.updateLocalUser(user);
        }
      }
    });
  }

  getData() {
    let user = this._user.getUserValue;
    this.indexeddbService.getDataFromTable('profile', 'route').then(itemRoute => {
      this.profile = user?.profile;
      this.route = itemRoute;
      this.profileFormGroup.controls.fleet.setValue(this.profile?.fleet);
      this.profileFormGroup.controls.operator.setValue(this.profile?.operator);
    });
  }

  closeClick(getFleet: boolean): void {
    this.dialogRef.close(getFleet);
  }
  
  // ngOnDestroy(){
  //   this.showNav = false;
  // }
}
