import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { environment } from 'src/environments/environment';
import { IRemoteConfigParam } from '../../domain/frc/frc.interface';

type TParamRemoteConfig = "communications" | "messages" | "version" | "allowed_operators_documents" | "allow_button_scan_qr_apple" | "refresh_token" | string;
@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {

  constructor(
    private httpClient: HttpClient, 
    private constants: PilotConstants
  ) { }

  getParameterRemoteConfig(key: TParamRemoteConfig, tk?: boolean): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.APPLICATION_FIREBASE_PATH + this.constants.REST_V1}${this.constants.REMOTE_CONFIG_PATH}`;
    const params = new HttpParams().set('key', key);

    if(tk) {
      let headers = new HttpHeaders({
        'Authorization': `Bearer ${null}`,
        'Accept': 'application/json'
      });
      return this.httpClient.get<any>(URL, { params, headers });
    }
    return this.httpClient.get<any>(URL, { params });
  }

  setParameterRemoteConfig(data: IRemoteConfigParam): Observable<any> {
    const URL = `${environment.apiUrl}${this.constants.APPLICATION_FIREBASE_PATH + this.constants.REST_V1}${this.constants.REMOTE_CONFIG_PATH}`;
    return this.httpClient.post(URL, data, { responseType: "text" });
  }


}