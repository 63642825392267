import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from 'rxjs';
import { UserService } from '../user/user.service';
import { SecurityService } from '../security/security.service';
import { confirmAlert } from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {

  isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private _user: UserService,
    private securityService: SecurityService,
  ) { }
  
  addHeaders(req: HttpRequest<any>) {
    let contentType = 'application/json';
    let isLoginQR = this._user.user?.isLoginQR;
    const idToken = this._user?.user?.idToken;
    let headers: any = {
      'Authorization': `Bearer ${idToken}`,
      'Accept': contentType
    }

    if (isLoginQR) {
      headers = { ...headers, "X-API-Key": this._user.user.jwt };
    }

    if (idToken) {
      req = req.clone({
        headers: new HttpHeaders(headers)
      });
    }
    return req;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;
    return next.handle(this.addHeaders(request)).pipe(
      catchError((err: HttpErrorResponse) => {
        return this.handleResponseError(err, request, next);
      })
    );
  }

  handleResponseError(err: HttpErrorResponse, request?: HttpRequest<any>, next?: HttpHandler) {
    
    if (err instanceof HttpErrorResponse && this._checkTokenExpiryErr(err)) {
      // refresh token here
      return this.handle401Error(request, next, err);
    }
    if (err instanceof HttpErrorResponse && err.status == 403 && request.url.includes('dispatch/loadsheet')) {
      // user not authorized to access this resource
      this.handleForbiddenError();
    }
    return throwError(() => err);
  }

  private handleForbiddenError() {
    console.error("handleForbiddenError");
    confirmAlert({
      title: "Not authorized",
      text: "You do not have permission to access this resource. Please contact support if you believe this is a mistake.",
      showCancelButton: false,
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      icon: "error"
    }, true).then((confirm) => {
      this.securityService.logOut();
    });
  }

  private _checkTokenExpiryErr(err: HttpErrorResponse): boolean {
    console.log("checkTokenExpiryErr", err);
    return (
      err?.status === 401 &&
      err?.error?.code == 401 &&
      err?.error?.message?.includes("Jwt is expired")
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);
      this.securityService.refreshToken().then((token) => {
        this.tokenSubject.next(token);
        this.isRefreshingToken = false;
        console.log("refreshToken success", token);;
        return next.handle(this.addHeaders(request));
      })
        .catch((refreshTokenError) => {
          console.error("refresTokenError ", refreshTokenError);
          confirmAlert({
            title: "Session Expired",
            text: "Please login again",
            showCancelButton: false,
            confirmButtonText: "OK"
          }).then((confirm) => {
            this.securityService.logOut();
            return throwError(() => err);
          });
        });
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addHeaders(request));
        })
      );
    }
  }
}
