<main class="m-through-nav">
    <div class="container pb-5">
        <h2 class="title-newsletter latam_bold_italic">Communications</h2>

        <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="selectedIndex = $event.index">
            <mat-tab>
                <ng-template matTabLabel>
                    <span [matBadge]="'+' + numberNoAckSafetyDocuments" matBadgeOverlap="false"
                        [matBadgeHidden]="numberNoAckSafetyDocuments == 0" matBadgeSize="small">
                        Safety
                    </span>
                </ng-template>
                <ng-template matTabContent>
                    <app-view-documents (emitNumberOfNoAck)="numberNoAckSafetyDocuments = $event" [type]="{ section: 'Security' }"></app-view-documents>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template matTabLabel>
                    <span [matBadge]="'+' + numberNoAckOperationalDocuments" matBadgeOverlap="false"
                        [matBadgeHidden]="numberNoAckOperationalDocuments == 0" matBadgeSize="small">
                        Op. Documents
                    </span>
                </ng-template>
                <ng-template matTabContent>
                    <app-view-documents
                        [type]="{ section: 'Operational' }"></app-view-documents>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

    </div>
</main>