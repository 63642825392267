import * as moment from 'moment';
import { FlightId } from '../domain/flight-id.model';
import { NetworkService } from '../services/network/network.service';
import { RolService } from '../services/rol.service';
import { Injectable } from '@angular/core';
import { Activity } from '../domain/roster/activity.model';
import { IndexeddbService } from '../services/indexeddb.service';
import { CrewAssignmentRequest } from '../domain/crew-assignment-request';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RosterCommon {
  public isConnected: boolean;

  constructor(
    private _network: NetworkService,
    private rolService: RolService,
    private indexeddbService: IndexeddbService
  ) { }

  public async monitorNetWorkState() {
    this.isConnected = await this._network.getStatusNetwork();
  }

  public getSearchDates(datePattern: string, isFromHome = false) {
    let today = moment().utc(true);
    let addDays = moment().add(isFromHome? 7:30, 'days');

    let startDate: string = today.format(datePattern);
    let endDate: string;
    
    if (isFromHome) {
      endDate = moment(addDays, datePattern).format(datePattern);
    } else if (parseInt(moment(today, datePattern).format("DD")) >= 27) {
      endDate = moment(addDays, datePattern).endOf('month').format(datePattern);
    } else {
      endDate = moment(today, datePattern).endOf('month').format(datePattern);
    }

    return { startDate, endDate };
  }

  setFlightIdsRol(roster: Array<Activity>, caeFlights: any[], searchDateFormat: string) {
    caeFlights?.forEach(caeFlight => {
      roster.filter( f => {
        return f.flightLeg?.flightNumber == caeFlight.flnr
         && f.flightLeg?.departure.iataCode == caeFlight.departureAerodrome.iataID 
         && f.flightLeg?.aircraftRegistration.replace("-", "") == caeFlight.acreg 
         && f.flightLeg?.flightDate == searchDateFormat
      }).forEach(f => f.dispatchId = caeFlight.fltLegId);
    });
  }

  private filterFlights(roster: Array<Activity>, daysForward: any) {
    return roster.filter(f => f.isFlight() && moment(f.flightLeg.departure.dates.utc).isBefore(daysForward))
      .map(f => {
        let flightId = new FlightId({ 
          flightNumber: f.flightLeg.flightNumber,
          tail: f.flightLeg.aircraftRegistration,
          operator: f.flightLeg.carrier,
          flightDate: f.flightLeg.departure.dates.utc,
          departureIataCode: f.flightLeg.departure.iataCode,
          arrivalIataCode: f.flightLeg.arrival.iataCode
        });
        return flightId;
      });
  }

  saveCrewInformation(roster: Array<Activity>) {
    let flightsToQuery: Array<FlightId> = this.filterFlights(roster, moment().add(2, 'days'));
    this.getNextCrewFlights(flightsToQuery);
  }

  async getNextCrewFlights(flights: Array<FlightId>) {
    let flightsCrew = [];
    for (const iterator of flights) {
      let flightCrewRequest = new CrewAssignmentRequest();
      flightCrewRequest.flightNumber = iterator.flightNumber.toString();;
      flightCrewRequest.carrier = iterator.operator;
      flightCrewRequest.departureDate = moment(iterator.flightDate).format('DD-MM-YYYY');
      flightCrewRequest.flightDate = moment(iterator.flightDate).format('YYYY-MM-DD');
      flightCrewRequest.departure = iterator.departureIataCode;
      flightCrewRequest.arrival = iterator.arrivalIataCode;
      let response = await firstValueFrom(this.rolService.getCrewRosterAssignment(flightCrewRequest));
      let data = { ...flightCrewRequest, crew: response };
      flightsCrew = [...flightsCrew, data];
    }
    this.indexeddbService.addToTable('rol', flightsCrew, "flightsCrew");
  }
}