import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { PilotConstants } from '../../commons/pilot-constants';
import { IndexeddbService } from '../../services/indexeddb.service';
import { SetFuelSafetyService } from '../../services/set-fuel-safety.service';
import { DateToBackPipe } from '../../commons/pipes/date-to-back.pipe';

import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

import { CommonsService } from '../../../../core/services/commons/commons.service';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { PreferencesService } from 'src/app/core/services/preferences/preferences.service';
import { IUser } from 'src/app/modules/pilot/domain/user/user';
import { UiService } from 'src/app/modules/pilot/services/ui/ui.service';

@Component({
    selector: 'app-safety',
    templateUrl: './safety.component.html',
    styleUrls: ['./safety.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SafetyComponent implements OnInit, OnDestroy {

  keysDataIndicators = this.constants.KEYS_DATA_INDICATORS;
  dataGraphicsIndicators = {};

  fleet: any = {};
  fleetList: Array<any>;
  
  isConnected: boolean = true;

  events: any;

  showLoading: boolean = true;
  isHiddenAlpha: boolean = true;
  isHiddenStick: boolean = true;

  airportsList: string[] = [];

  fleetSelected: string;
  componentShow: string = "EVENTS";
  currentDate: string;

  showNav: boolean = false;
  isOpen: boolean = false;
  tabSelected = 0;

  user: IUser;
  userSubs: Subscription;
  refreshSubs: Subscription;

  constructor(
    private constants: PilotConstants,
    private _network: NetworkService,
    private setFuelSafetyService: SetFuelSafetyService,
    public dialog: MatDialog,
    private commonsService: CommonsService,
    private indexeddbService: IndexeddbService,
    private dateBack: DateToBackPipe,
    private _user: UserService,
    private _preferences: PreferencesService,
    private _ui: UiService
  ) {

    const valideNetwork = async () => {
      this.isConnected = await this._network.getStatusNetwork();
      this._network.getState().subscribe(isConnected => {
        this.isConnected = isConnected;
      });
    }
    valideNetwork();
  }

  ngOnInit() {
    this.refreshSubs = this._ui.getStateRefresh().subscribe( state => {
      if (state.refresh)  {
        this.userSubs?.unsubscribe();
        this.init(true);
      };
    });
    this.init();
  }

  ngOnDestroy(): void {
    this.userSubs?.unsubscribe();
    this.refreshSubs?.unsubscribe();
  }

  init(refresh: boolean = false) {
    this.fleetList = this.constants.FLEET_LIST;

    this.currentDate = moment().subtract(1, 'months').format('MMM/YY');

    this.userSubs = this._user.getUser().subscribe((user) => {
      this.user = user;
      let { fleet } = this.user?.profile;
      this.fleet = fleet;

      if (fleet == 'B787F' || fleet == 'A350' || fleet == 'B767' || fleet == 'B777') {
        this.isHiddenAlpha = false;
      };
      if (fleet == 'A320F' || fleet == 'A350') {
        this.isHiddenStick = false;
      };

      this.fleetSelected = fleet;

      this.showLoading = true;
      this.validateDateAndDataSafety().then(async (item) => {
        if (item) {
          await this.setFuelSafetyService.setData("safety", "WOR");
        } 
        this.setDataIndicators(refresh);
        this.showLoading = false;
      }).catch(
        err => {
          refresh && this._ui.setStateRefresh({ refresh: false, finishEvent: true });
          this.showLoading = false;
        }
      );
    });
  }

  validateDateAndDataSafety(): Promise<boolean> {
    let date = this.dateBack.transform(new Date(), true, 0);
    return new Promise((resolve, reject) => {
      this.indexeddbService.getDataFromTable('safety', 'events')
        .then(response => {
          if (response != undefined && response[this.fleet] != undefined) {
            this._preferences.getItem('TodaySafety').then(value => {
              if (!value || date != value || !this.isConnected) {
                this._preferences.setItem("TodaySafety", date);
                resolve(true);
              } else {
                resolve(false);
              }
            });
          } else {
            if (this.isConnected) {
              this._preferences.setItem("TodaySafety", date);
              resolve(true);
            } else {
              resolve(false);
            }
          }
        });
    })
  }
  
  setDataIndicators(refresh: boolean) {
    for (const key of this.keysDataIndicators) {
      this.indexeddbService.getDataFromTable('safety', key).then((data: any) => {
        if (data?.[this.fleet]) {
          this.dataGraphicsIndicators[key] = data[this.fleet];
        }
      });
    }
    setTimeout(() => {
      refresh && this._ui.setStateRefresh({ refresh: false, finishEvent: true });
    }, 2000);
  }

  changeTabInfo(tabInfo: any) {
    this.isOpen = false;
    this.componentShow = tabInfo.tab.textLabel;
    this.commonsService.registryEvent({ category: "Safety", event: "view_statistics_safety_section" });
  }

}
