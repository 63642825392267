import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { confirmAlert } from 'src/app/core/utils/utils';
import { FlightLogInfo } from 'src/app/modules/pilot/domain/flight-log/flight-log-info.model';
import { FlightLogRulesDates } from 'src/app/modules/pilot/domain/flight-log/flight-log-rules-dates';
import { FlightLog } from 'src/app/modules/pilot/domain/flight-log/flight-log.model';
import { CommonsService } from 'src/app/core/services/commons/commons.service';
import { FlightLogService } from 'src/app/modules/pilot/services/flight-log.service';
import { IndexeddbService } from 'src/app/modules/pilot/services/indexeddb.service';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'add-flight-logs',
    templateUrl: './add-flight-logs.component.html',
    styleUrls: [
        './add-flight-logs.component.scss',
        './../../../commons/styles/custom-inputs.scss'
    ],
    standalone: false
})
export class AddFlightLogsComponent implements OnInit {

  @Input() selectedFlights: FlightLogInfo[] = [];
  @Output() finishEvent = new EventEmitter<boolean>();
  @Input() editMode: string;
  @Input() rulesDates: FlightLogRulesDates;

  public flightLogToCreate: FlightLog;

  public selectedFile: File = null;
  public filePlaceholder: string;

  public formflightLog: FormGroup = this._fb.group({
    flightLogNumber: ['', [Validators.required, Validators.max(99999999999999999999)]],
    bookImage: ["", Validators.required],
    flights: this._fb.array([])
  }, { updateOn: 'blur' });

  public saving: boolean = false;
  isConnected: boolean;

  constructor(
    private _fb: FormBuilder,
    private flightLogService: FlightLogService,
    public constants: PilotConstants,
    private commonsService: CommonsService,
    public _network: NetworkService,
    private indexeddb: IndexeddbService,
  ) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.isConnected = await this._network.getStatusNetwork();
    this._network.getState().subscribe(isConnected => this.isConnected = isConnected);
    this.commonsService.registryEvent({ category: 'flight_log', event: "flight_log_main_page"});
    this.flightLogToCreate = new FlightLog(null, null);
    this.selectedFile = null;
    
    this.filePlaceholder = this.editMode ? 'Replace the current file loaded' : 'Upload an image';

    this.selectedFlights.forEach(f => this.addFlight(f));
    this.setForm();
    this.listenForm();
  }

  addFlight(item: FlightLogInfo) {
    const flightForm = this._fb.group({
      startTime: [item.dates.startTime?.substring(0, 5), [Validators.required, Validators.minLength(4)]],
      endTime: [item.dates.endTime?.substring(0, 5), [Validators.required, Validators.minLength(4)]],
      blockTime: [item.dates.blockTime?.substring(0, 5), Validators.required],
    }, { updateOn: "blur" });
    this.flightInfo.push(flightForm);
  }

  get flightInfo() {
    return this.formflightLog.controls["flights"] as FormArray;
  }

  async saveFlightLog() {
    
    let formValues = this.formflightLog.getRawValue();
    this.flightLogToCreate.flights = this.selectedFlights.map((sf, i) => {
      sf.dates.startTime = this.formatTimeField(formValues["flights"][i].startTime);
      sf.dates.endTime = this.formatTimeField(formValues["flights"][i].endTime);
      sf.dates.blockTime = formValues["flights"][i].blockTime;
      return sf;
    });
    this.flightLogToCreate.bookImage = this.selectedFile;
    this.flightLogToCreate.flightLogNumber = formValues["flightLogNumber"];

    let text = "";
    this.flightLogToCreate.flights.forEach(f => {
      text += ` <li>${f.operatingAirlineCode}${f.flightNumber} BLOCK TIME: <strong style="font-family: 'Latam_Sans_Bold', arial, sans-serif">${f.dates.blockTime}</strong> HRS</li>`;
    })


    let confirm = await confirmAlert({
      title: "Save Flight Log",
      html: `
        <p>Please confirm that this information is correct:</p>
        <ul>
          ${text}
        </ul>
      `,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel"
    }, true);
    
    if (!confirm) return;

    // return;
    this.saving = true;
    if (this.isConnected) {
      this.flightLogService.createFlightLog(this.flightLogToCreate)
        .pipe(finalize(() => this.saving = false))
        .subscribe({
          next: resp => {
            this.commonsService.registryEvent({ category: 'flight_log', event: "create_flight_log"});
            confirmAlert({
              icon: 'success',
              title: 'Created',
              text: 'Your flightlog has been created successfully',
              showCancelButton: false,
              confirmButtonText: "OK",
            }, true).then(async resp => {
              this.finishEvent.emit();
            });
          },
          error: err =>
            confirmAlert({
              icon: 'error',
              title: 'Error',
              text: `There was a problem creating your flight log # ${this.flightLogToCreate.flightLogNumber}`,
              showCancelButton: false,
              confirmButtonText: "OK",
            }, true)
        });
    } else {
      this.indexeddb.getDataFromTable('rol', 'flightlogs').then(flightlogs => {
        let flogs:FlightLog[]  = flightlogs ?? [];
        flogs.push(this.flightLogToCreate);
        this.indexeddb.addToTable('rol', flogs, "flightlogs");
        this.commonsService.registryEvent({ category: 'flight_log', event: "save_flight_log_offline"});
        confirmAlert({
          icon: 'success',
          title: 'Thank you',
          text: 'Your flightlog will be sent as soon as the network is available',
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }, true).then((result) => {
          if (result) {
            this.finishEvent.emit();
          }
        });
      });
    }
  }

  public isFlightLogAlreadyCreated(value: string) {
    if (!value || !this.isConnected) return;
    this.flightLogService.checkIfFlightLogExists(Number(value)).subscribe(
      (exists: boolean) => {
        if (exists) {
          confirmAlert({
            icon: 'warning',
            title: 'Already created',
            text: `A flight log with this number "${Number(value)}" is already created, you can edit it.`,
            showCancelButton: false,
            confirmButtonText: "OK"
          }, true);
          this.formflightLog.controls['flightLogNumber'].setValue('', { emitEvent: false });
          this.flightLogToCreate.flightLogNumber = null;
        } else {
          this.flightLogToCreate.flightLogNumber = value;
        }
      },
    );

  }

  public onAttachmentSelected(event: any) {
    if (event.target.files.length > 0) {
      let attachment = event.target.files[0] || null;
      if (attachment?.size <= 5000000) {
        this.selectedFile = attachment;
        this.formflightLog.controls['bookImage'].setValue(attachment?.name, { emitEvent: false });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'The attachment exceeds the size limit'
        });
      }
    }
  }

  private getDifferenceTime(start: string, end: string) {
    if (!start || !end) return null;
    let startTime = moment(start, "HH:mm"),
      endTime = moment(end, "HH:mm");
    let hrs = moment.utc(endTime.diff(startTime)).format("HH");
    let min = moment.utc(endTime.diff(startTime)).format("mm");
    return [hrs, min].join(':');
  }

  private setForm() {
    this.flightInfo.controls.forEach(control => control.get("blockTime").disable({ emitEvent: false }));
  }

  private listenForm() {

    this.formflightLog.controls.flightLogNumber.valueChanges
      .subscribe(value => this.isFlightLogAlreadyCreated(value));

    this.flightInfo.controls.forEach(control => {
      control.valueChanges.subscribe(input => {
        if (input.startTime && input.endTime) {
          let blockTime = this.getDifferenceTime(input.startTime, input.endTime)
          if (blockTime) {
            control.patchValue({ blockTime }, { emitEvent: false });
          }
        }
      })
    });
  }

  private formatTimeField(str: string) {
    str = str.replace(':', '');
    return [str.slice(0, 2), ':', str.slice(2)].join('');
  }

}
