import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

/* Commons */
const BFF_PATH = 'LatamApiPilotBFF/v1/';
const APPLICATION_ROLE_PATH = 'LatamApiPilotRole/';
const APPLICATION_FIREBASE_PATH = 'LatamApiFirebase/';

const LOGIN_API = 'login/validateUser/communications';
const COMMUNICATION_API_NEW_NEWS = 'communications';
const COMMUNICATION_API_UPDATE_COMMUNICATION = 'communications/update';
const COMMUNICATION_API_PUBLISH_COMMUNICATION = 'communications/publish';


const COMMUNICATION_API_NOTIFICATIONS = 'notifications';
const COMMUNICATION_API_SEND_NOTIFICATIONS = 'fcm/send-custom-push';
const COMMUNICATION_API_TYPES = 'communications/types';
const COMMUNICATION_API_NEWS = 'communications';
const COMMUNICATION_UPDATE = 'communications'
const COMMUNICATIONS_ACK_REPORT = '/report/acknowledge';
const COMMUNICATIONS_ALL_ACK_REPORT = '/report/all-acknowledge';
const COMMUNICATIONS_BLOCKED_FLIGHTS = 'blocked-flights';

const FAQ_BASE_PATH = 'faq';
const GET_ALL_FAQS = '/all';
const CREATE_FAQ = '/create';
const UPDATE_FAQ = '/update';
const DELETE_FAQ = '/delete/';

const MEDAL_GOAL_BASE_PATH = 'medal-goal';
const GET_ALL_MEDAL_GOALS = '/all';
const UPDATE_MEDAL_GOAL = '/update';
const GET_MEDAL_RANKING = '/ranking';

const SAFETY_BASE_PATH = 'communications/safety/';
const UPLOAD_SAFETY_VIDEO = 'video';
const CHECK_IF_EVENT_EXISTS = 'check-if-event-exists/';

const DISPATCH_GET_ALL = 'dispatch/all';

/* UPLOADFILE API */
const UPLOAD_FILE = 'uploadFile/kpi-fuel';
const UPLOAD_SAFETY_FILE = 'uploadFile/kpi-safety';
const GET_SIGNED_URL ='uploadFile/file-url';

const ROUTE_REPORT_BASE_PATH ='v1/report/route-report';
const ROUTE_REPORT_CREATE_PATH ='/create';
const ROUTE_REPORT_UPDATE_PATH ='/update';

const LOCAL_ROUTES: {[name:string]: { name: string, roles: string[], route: string}} = {
  'communication': {name: 'Communications', roles: ['COMMUNICATIONS', 'SAFETY'],  route: 'communication-maintainer'},
  'faq': {name: 'FAQ', roles: ['FAQ'],  route: 'faq-maintainer'},
  'safety': {name: 'Safety', roles: ['SAFETY'],  route: 'safety'},
  'fuel': {name: 'Fuel', roles: ['FUEL'],  route: 'fuel'},
  'medalGoal': {name: 'Medal Goal', roles: ['MEDAL_GOALS'],  route: 'medal-goal-maintainer'},
  'dispatch': {name: 'Dispatch', roles: ['DISPATCH'],  route: 'dispatch'},
  'routeReport': {name: 'Route Report', roles: ['DISPATCH'],  route: 'route-report'},
  'news': {name: 'News Maintainer', roles: ['NEWS'],  route: 'news'},
  'notifications': {name: 'Notifications', roles: ['SEND_PUSH_NOTIFICATIONS'],  route: 'notifications'},
  'blockedFlights': {name: 'Blocked Flights', roles: ['BLOCKED_FLIGHTS'],  route: 'blocked-flights'},
  'config': {name: 'Config', roles: ['PILOT_CONFIG'],  route: 'config'},
};

@Injectable()
export class ConstantsTools {

  BFF_PATH: string = BFF_PATH;
  LOGIN_API: string = LOGIN_API;
  COMMUNICATION_API_NEW_NEWS: string = COMMUNICATION_API_NEW_NEWS;
  COMMUNICATION_API_UPDATE_COMMUNICATION: string = COMMUNICATION_API_UPDATE_COMMUNICATION;
  COMMUNICATION_API_PUBLISH_COMMUNICATION: string = COMMUNICATION_API_PUBLISH_COMMUNICATION;
  COMMUNICATION_API_NOTIFICATIONS: string = COMMUNICATION_API_NOTIFICATIONS;
  COMMUNICATION_API_SEND_NOTIFICATIONS: string = COMMUNICATION_API_SEND_NOTIFICATIONS;
  COMMUNICATION_API_TYPES: string = COMMUNICATION_API_TYPES;
  COMMUNICATION_API_NEWS: string = COMMUNICATION_API_NEWS;
  COMMUNICATION_UPDATE: string = COMMUNICATION_UPDATE;
  COMMUNICATIONS_ACK_REPORT: string = COMMUNICATIONS_ACK_REPORT;
  COMMUNICATIONS_ALL_ACK_REPORT: string = COMMUNICATIONS_ALL_ACK_REPORT;

  COMMUNICATIONS_BLOCKED_FLIGHTS: string = COMMUNICATIONS_BLOCKED_FLIGHTS;

  FAQ_BASE_PATH: string = FAQ_BASE_PATH;
  GET_ALL_FAQS: string = GET_ALL_FAQS;
  CREATE_FAQ: string = CREATE_FAQ;
  UPDATE_FAQ: string = UPDATE_FAQ;
  DELETE_FAQ: string = DELETE_FAQ;

  MEDAL_GOAL_BASE_PATH: string = MEDAL_GOAL_BASE_PATH;
  GET_ALL_MEDAL_GOALS: string = GET_ALL_MEDAL_GOALS;
  UPDATE_MEDAL_GOAL: string = UPDATE_MEDAL_GOAL;
  GET_MEDAL_RANKING: string = GET_MEDAL_RANKING;

  SAFETY_BASE_PATH: string = SAFETY_BASE_PATH;
  UPLOAD_SAFETY_VIDEO: string = UPLOAD_SAFETY_VIDEO;
  CHECK_IF_EVENT_EXISTS: string = CHECK_IF_EVENT_EXISTS;

  DISPATCH_GET_ALL: string = DISPATCH_GET_ALL;

  UPLOAD_FILE: string = UPLOAD_FILE;
  UPLOAD_SAFETY_FILE: string = UPLOAD_SAFETY_FILE;
  GET_SIGNED_URL: string = GET_SIGNED_URL;

  APPLICATION_ROLE_PATH: string = APPLICATION_ROLE_PATH;
  APPLICATION_FIREBASE_PATH: string = APPLICATION_FIREBASE_PATH;
  ROUTE_REPORT_BASE_PATH: string = ROUTE_REPORT_BASE_PATH;
  ROUTE_REPORT_CREATE_PATH: string = ROUTE_REPORT_CREATE_PATH;
  ROUTE_REPORT_UPDATE_PATH: string = ROUTE_REPORT_UPDATE_PATH

  OPERATOR = [
    { id: 1, name: '4C' },
    { id: 2, name: '4M' },
    { id: 3, name: 'JJ' },
    { id: 4, name: 'L7' },
    { id: 5, name: 'LA' },
    { id: 6, name: 'LP' },
    { id: 7, name: 'LU' },
    { id: 8, name: 'M3' },
    { id: 9, name: 'M7' },
    { id: 10, name: 'MY' },
    { id: 11, name: 'PZ' },
    { id: 12, name: 'UC' },
    { id: 13, name: 'XL' }
  ];

  FLEET_LIST = [
    { id: "A320F", name: 'A320F' },
    { id: "A350", name: 'A350' },
    { id: "B767", name: 'B767' },
    { id: "B777", name: 'B777' },
    { id: "B787F", name: 'B787F' }
  ];

  KPI_FUEL_TYPE = [
    { id: "CLIMB CI 0", name: 'cci' },
    { id: "EXTRA FUEL", name: 'fuel' },
    { id: "APU SHUTDOWN", name: 'apu' },
    { id: "STAB. ALT", name: 'stab-alt' }
  ];

  ICONS = [

    {
      url: 'assets/img/home/new-home/communications/app-icon.svg',
      name: "Default"
    },
    {
      url: 'assets/img/home/new-home/communications/qr_code.svg',
      name: "QR Code"
    },
    {
      url: 'assets/img/home/new-home/communications/poll.svg',
      name: "Poll"
    },
    {
      url: 'assets/img/home/new-home/communications/tickets.svg',
      name: "Ticket"
    },
    {
      url: 'assets/img/home/new-home/communications/clara-chat.png',
      name: "Clara Chat"
    },
    {
      url: 'assets/img/home/new-home/banners/flightlog.svg',
      name: "Documents"
    },
    {
      url: 'assets/img/home/new-home/communications/upload.svg',
      name: "Upload"
    },
    {
      url: 'assets/img/svg/avion.svg',
      name: "Flight"
    }



    
  ];

  handleError = (err: HttpErrorResponse) => { throw err.status };

  LOCAL_ROUTES = LOCAL_ROUTES;

  ROLES_PILOT_TOOLS = ["SAFETY", "COMMUNICATIONS", "FAQ", "MEDAL_GOALS", "DISPATCH", "FUEL", "NEWS"]
}
