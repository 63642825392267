import { Component, OnInit, Input, HostListener, Output, EventEmitter, SimpleChanges, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SecurityService } from 'src/app/core/services/security/security.service';
import { Profile } from '../../modules/pilot/domain/profile';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ProfileModalComponent } from '../../modules/pilot/components/modals/profile-modal/profile-modal.component';
import { CommonsService } from '../../core/services/commons/commons.service';
import { NewsletterService } from 'src/app/modules/pilot/services/newsletter.service';
import { NetworkService } from 'src/app/modules/pilot/services/network/network.service';
import { ProfileService } from 'src/app/modules/pilot/services/profile.service';
import { PreferencesService } from 'src/app/core/services/preferences/preferences.service';
import { PilotConstants } from 'src/app/modules/pilot/commons/pilot-constants';
import { NotificationsService } from 'src/app/modules/pilot/services/notifications/notifications.service';
import { INotifications } from 'src/app/modules/pilot/domain/notifications/notifications';
import { PushNotificationsService } from 'src/app/modules/pilot/services/push-notifications/push-notifications.service';
import { IUser } from 'src/app/modules/pilot/domain/user/user';
import { Subscription } from 'rxjs';
import { UiService } from 'src/app/modules/pilot/services/ui/ui.service';
import { environment } from 'src/environments/environment';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { RemoteConfigService } from 'src/app/modules/pilot/services/remote-config/remote-config.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    standalone: false
})

export class NavbarComponent implements OnInit, OnDestroy {
  
  @Input('locationNav') locationNav: string;
  @Input('profileInfo') profileInfo;
  @Input('route') route;
  @Input('user') user: IUser;;
  @Output() toggleSidenav: EventEmitter<any> = new EventEmitter();
  public auth2: any;
  // count = 0;
  dialogRef: any;
  innerWidth: any;
  profile: Profile = new Profile();
  routes = { departure: null, arrival: null};
  isConnected:boolean;
  noBoxShadow: boolean;
  medalType: string = null;
  changeBpIsAllowed = false;
  hideSection: boolean = this.constants.hideSectionsStores;
  isTestEnvironment: boolean = !environment.production;

  // Notifications
  showNotificationsModal: boolean = false;
  numberNotifications = 0;
  notifications: INotifications[] = [];
  refreshSubs: Subscription;

  allowApp: boolean = false;
  isWeb: boolean = false;
  
  allowedOperatorsOperationsEvents: string[] = [];
  allowTabOperationalEvents: boolean = false;

  constructor(
    public router: Router,
    private securityService: SecurityService,
    public dialog: MatDialog,
    private _network: NetworkService,
    private commonsService:CommonsService,
    public snackBar: MatSnackBar,
    private profileService: ProfileService,
    private _preferences: PreferencesService,
    private constants: PilotConstants,
    private notificationsService: NotificationsService,
    private pushNotificationService: PushNotificationsService,
    private ngZone: NgZone, 
    private _ui: UiService,
    private newsletterService: NewsletterService,
    private remoteConfig: RemoteConfigService,
  ) {
    this._preferences.getItem('medal_type').then( value => this.medalType = value);
  }

  ngOnInit() {
    Device.getInfo().then(async info => {
      if (info.platform != "web") {
        this.isWeb = false;
        App.getInfo().then(app => {
          this.allowApp = app.id == "com.latam.mobile.pilotapp";
        });
      } else {
        this.isWeb = true;
        this.allowApp = true;
      }
    });
    const userRoles: string[] = this.user?.roles;
    this.changeBpIsAllowed = userRoles?.includes('BP_CONVERSION');
    
    const valideNetwork = async () => {
      this.isConnected = await this._network.getStatusNetwork();
      this._network.getState().subscribe(state => this.isConnected = state);
    }
    valideNetwork();

    this.innerWidth = window.innerWidth;
    
    this.refreshSubs = this._ui.getStateRefresh().subscribe( state => {
      if (state.refresh)  {
        this.getNotifications();
      };
    });

    this.pushNotificationService.getState().subscribe(
      value => {
        if (value.notificationsBell) {
          this.getNotifications();
        }
      }
    );

    this.getNotifications();

    this.getConfigDocumentsAllowedByOperators();
  }

  ngOnDestroy(): void {
    this.refreshSubs?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.profileInfo != undefined) this.profile = this.profileInfo;
    if(this.route != undefined) this.routes = this.route;
    this.noBoxShadow = (this.router.url.substring(0, 5) === '/fuel' ) ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  logOutGoogle() {
    Swal.fire({
      text: 'Are you sure you want to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.commonsService.registryEvent({category:"TopNav", event: "Logout"});
        this.securityService.logOut();
      }
    });
  }

  cleanCache(){
    this.securityService.logOut();
  }

  toggleSidenavMethod(){
    this.toggleSidenav.emit(true);
  }

  settings() {
    if (this.isConnected) {
      this.dialogRef = this.dialog.open(ProfileModalComponent, {
        maxWidth: '95vw',
        data: { message: "Settings" },
        disableClose: true
      });
    }
  }

  changeBp(){
    Swal.fire({
      title: 'Type the bp',
      icon: 'info',
      input: 'number',
      inputPlaceholder: 'For example: 00123456',
      inputValidator: result => {
        if (!result) {
          return 'Please enter the bp';
        }
        else if(!(/^\d+$/.test(result))){
          return 'Only numbers allowed';
        }
        else if (result.length > 8) {
          return 'You cannot enter more than 8 characters';
        }
      },
      showCancelButton: true,
      confirmButtonText: 'Ok',
      reverseButtons: true
    }).then(bp => {

      if(bp.dismiss)return;
      
      this.profileService.updateBp(bp.value).subscribe({
        next: resp => {
          this.router.navigate(['/roster']);
          Swal.fire({
            icon: 'success',
            title: 'Bp updated successfully'
          });
        },
        error: error => {
          if(error.status == 403){
            Swal.fire({
              icon: 'warning',
              title: "You don't have permissions to change the bp"
            })
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Error changing your bp'
            })
          }
        }
      });
    });
  }

  getNotifications() {
    this.notificationsService.getNotifications().subscribe({
      next: (notifications) => {
        this.ngZone.run(() => {
          this.notifications = notifications;
          let state = this.pushNotificationService.getPushNotificationsValue;
          let notificationsunView = notifications.filter(n => !n.view);
          this.numberNotifications = notificationsunView?.length || 0;
          if (state.notificationsBell) {
            setTimeout(() => {
              this.pushNotificationService.setStatePushNotifications({...state, notificationsBell: false });
            }, 1000);
          }
        })
        this.setUpNotificationsCommunications();
      }
    });
    
  }

  sendToHome() {
    this.commonsService.registryEvent({category: "home", event: "TopNav"});
    this.router.navigate(["home"]);
  }

  toProfile(){
    this.commonsService.registryEvent({event: "profile", category: "TopNav"});
    this.router.navigate(["profile"]);
  }

  toNotifications() {
    this.commonsService.registryEvent({ category: "TopNav", event: "notifications"});
    this.showNotificationsModal = true;
  }

  toQRView() {
    this.router.navigate(['qr'])
  }

  setUpNotificationsCommunications(){
    this.getTypeName(this.notifications);
    for (let notification of this.notifications){
      if(notification.typeNotification==="communications"){
        this.getAck(notification);
      }
    } 
  }

  getTypeName(notifications: INotifications[]){
    for (let notification of notifications){
      if (notification.typeNotification == "communications") {
        notification.operator = notification.description.substring(notification.description.indexOf("-") +1);
        notification.description= notification.description.substring(0, notification.description.indexOf("-"));
        if(notification.operator === "Informativos de seguridad"){
          notification.operator = "Inf. de Seguridad";
        }
      }
    }
  }

  getAck(notification: INotifications) {
    this.newsletterService.checkCommunicationAck(Number(notification.idLoadSheet)).subscribe(
      resp => {
        notification.communicationAck = resp;
    });
  } 

  private async getConfigDocumentsAllowedByOperators() {
    let contractCode = this.user?.profile?.contractCode;
    let key = 'allowed_operators_events';
    this.remoteConfig.getParameterRemoteConfig(key).subscribe(
      resp => {
        if (resp && Array.isArray(resp)) {
          this._preferences.setItem(key, resp);
          this.allowedOperatorsOperationsEvents = resp;
          this.allowTabOperationalEvents = this.allowedOperatorsOperationsEvents.includes(contractCode);
        }
      }
    );
  }

  
}
