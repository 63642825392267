import { Component, ViewChild, EventEmitter, Output, NgZone, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { SecurityService } from './core/services/security/security.service';
import { interval, Subscription } from 'rxjs';
import { CommonsService } from './core/services/commons/commons.service';
import { ProfileModalComponent } from './modules/pilot/components/modals/profile-modal/profile-modal.component';
import { NetworkService } from './modules/pilot/services/network/network.service';
import { UserService } from './core/services/user/user.service';
import { IUser } from './modules/pilot/domain/user/user';
import { Device } from '@capacitor/device';

import { register } from 'swiper/element/bundle';
import { PilotConstants } from './modules/pilot/commons/pilot-constants';
import { UiService } from './modules/pilot/services/ui/ui.service';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { environment } from 'src/environments/environment';
import { PushNotificationsService } from './modules/pilot/services/push-notifications/push-notifications.service';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { getAvailableAppVersion, getCurrentAppVersion, openAppStore, startFlexibleUpdate } from './core/utils/app-update-utils';

// register Swiper custom elements
register();

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {

  sidenavtype: MatDrawerMode = 'over';
  isConnected: boolean;
  nav: boolean = false;
  private sidenav: MatSidenav;
  public route: Promise<any>;
  showLoading: boolean;
  statusSendReport: boolean;
  innerWidth: any;
  innerHeight: any;
  componentShow = '';
  user: IUser;
  userSubs: Subscription;
  refreshSubs: Subscription;
  currentURL: string = '';
  hideSection: boolean = this.constants.hideSectionsStores;
  validateVersionApp = false;
  eventRefresh: any;
  disabledRefreshScreen = this.constants.DISABLED_REFRESH_SCREENS;
  disableRefresh: boolean = false;
  showSpinner: boolean = false;
  @Output() toggleSidenav: EventEmitter<any> = new EventEmitter();
  fireRefreshToken: boolean = true;

  @ViewChild('sidenav', { static: false }) set content(content: MatSidenav) {
    this.sidenav = content;
  }

  constructor(
    public _user: UserService,
    private router: Router,
    private securityService: SecurityService,
    private ngZone: NgZone,
    private commonsService: CommonsService,
    public dialog: MatDialog,
    private _network: NetworkService,
    public constants: PilotConstants,
    private _ui: UiService,
    private _pushNotifications: PushNotificationsService,
  ) {

    

    this.initializeApp();

    this.handleStateRefresh();

    this.handleRouterEvents();

    this.handleLoading();

    this.handleRouterEvents();

    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  ngOnInit() {

    const valideNetwork = async () => {
      this.isConnected = await this._network.getStatusNetwork();
      this._network.getState().subscribe(state => this.isConnected = state);
    }
    valideNetwork();

    this._ui.setStatusBar();
  }

  async initializeApp() {
    let info = await Device.getInfo();

    let isAppIpads: boolean = false;
    if (info.platform != 'web') {
      let app = await App.getInfo();
      isAppIpads = app.id == this.constants.IDS_IOS.ID_APP_STORE_ENTERPRISE;
      GoogleAuth.initialize({
        grantOfflineAccess: true,
        scopes: ['profile', 'email'],
        clientId: info.platform == "ios" ? environment.iosClientId : environment.androidClientId,
      });
      if (!isAppIpads) this.handleAutomaticUpdateApp();
    }
    this.userSubs = this._user.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
        this.initPush();
        !isAppIpads && this.initializeTokenRefresh();
      }
    });

    this.handleOperatorAndFleetConfig();
  }

  handleLoading() {
    this.securityService.showLoading.subscribe(
      value => {
        this.ngZone.run(() => this.showLoading = value)
      }
    );
  }

  handleStateRefresh() {
    this.refreshSubs = this._ui.getStateRefresh()
      .subscribe(state => {
        if (state.finishEvent) {
          this.eventRefresh?.target?.complete();
          setTimeout(() => { this.showSpinner = false; }, 1000);
        }
      });
  }

  handleRouterEvents() {
      this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url;
        this.disableRefresh = this.currentURL.includes("/reports") ? true : this.disabledRefreshScreen.includes(this.currentURL);
        if (this.sidenav != undefined && this.sidenav.opened) this.closeSidebar(true);
        this.nav = !(this.currentURL == "/login" || this.currentURL == "/on-boarding") ? true : false;
        this.valideUrl(event.url);
        (<any>window).gtag('config', 'UA-124922434-1', { 'page_path': event.urlAfterRedirects });
      }
    });
  }

  handleOperatorAndFleetConfig() {
    // Set operator and fleet is mandatory
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd && !value.url.includes('login') && !value.url.includes('on-boarding')) {
        if ((!this.user?.profile || !this.user?.profile?.operator) && this.isConnected) {
          const dialogRef = this.dialog.open(ProfileModalComponent, {
            width: "500px",
            data: { message: "Please, set an operator and fleet" },
            disableClose: true
          });

          dialogRef.afterClosed().subscribe(result => {
            const path = window.location.pathname;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate([path]));
          });
        }
      }
    });
  }

  handleAppRefresh(event: any) {
    this.eventRefresh = event;
    if (this.isConnected) {
      this.showSpinner = true;
      this._ui.setStateRefresh({ refresh: true, finishEvent: false });
    } else {
      this.eventRefresh?.target?.complete();
    }
  }

  private async handleAutomaticUpdateApp() {
    console.log("handleAutomaticUpdateApp invoked...")
    if (Capacitor.getPlatform() == "web") return;
    
    let actualVersion = await getCurrentAppVersion();
    let availableVersion = await getAvailableAppVersion();
    if (actualVersion != availableVersion) {
      if (Capacitor.getPlatform() == "android") {
        startFlexibleUpdate();
      } else {
        openAppStore();
      }
    }
  }

  initPush() {
    if (this.user?.idToken) {
      this._pushNotifications.initPush();
    }
  }

  async initializeTokenRefresh() {
    if (this.user?.idToken && this.fireRefreshToken) {
      this.fireRefreshToken = false;
      console.warn("initializeasasTokenRefreshfire");
      let refresh = !this.user.isLoginQR;
      console.log("refresh: ", refresh);
      if (refresh) {
        this.securityService.refreshToken();
        this.ngZone.runOutsideAngular(() => {
          // Refresh token every 20 minutes
          interval(1200000).subscribe(() => {
            console.warn("refresh token");
            this.securityService.refreshToken();
          });
        });
      }
    }
  }

  valideUrl(url: string) {
    if (url === '/') {
      this.componentShow = 'home';
    } else if (url.includes('fuel')) {
      this.componentShow = 'fuel';
      const x = document.getElementsByClassName('mat-drawer-content');
      x[0].scrollTop = 0;
    } else if (url.includes('flightInformation')) {
      this.componentShow = 'roster';
    } else if (url.includes('briefing')) {
      this.componentShow = 'briefing';
    } else if (url.includes('section=all') || url.includes('section=flights') || url.includes('section=activities')) {
      this.componentShow = 'roster';
    } else {
      this.componentShow = url.replace('/', '');
    }
  }

  showComponent(component: string) {
    this.componentShow = component;
    let event: string = "";
    switch (this.componentShow) {
      case 'safety':
        event = "Safety";
        break;
      case 'fuel':
        event = "Efficiency";
        break;
      case 'home':
        event = "Home";
        break;
    }
    this.commonsService.registryEvent({ category: 'menuButtonBar', event});
  }

  openSidebar(event) {
    this.sidenav.open();
  }

  closeSidebar(event) {
    this.sidenav.close();
  }

}
