<button mat-button (click)="closeClick();" class="button-close"><i class="material-icons"> clear </i></button>
<p class="time-ago latam_light">{{ newsletter.creationdate | dateAgo }}</p>
<p class="communication-title latam_bold">{{ newsletter.title }}</p>

<mat-dialog-content class="mat-typography">
    <ngx-extended-pdf-viewer [src]="newsletter.imgUrl" 
    [showDownloadButton]="false"
    [contextMenuAllowed]="false"
    [enablePrint]="false"
    [showSidebarButton]="false"
    [showOpenFileButton]="false"
    [showSecondaryToolbarButton]="false"
    height="80vh"></ngx-extended-pdf-viewer>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer-modal" *ngIf="!disabledAcknowdge">

    <div class="text-center"> <button (click)="download()" [disabled]="disabledAcknowdge"
        class="m-0 waves-light btn-acknowledge btn-mdb-color-pl">Download</button>
    </div>

    <div class="text-center">
        <span class="text-confirm">I confirm the reading of the document</span>
        <div class="text-center"> <button (click)="acknowledge()" [disabled]="disabledAcknowdge"
            class="m-0 waves-light btn-acknowledge btn-mdb-color-pl">Acknowledge</button>
        </div>
    </div>
</mat-dialog-actions>