import { Injectable } from '@angular/core';
import { PreferencesService } from 'src/app/core/services/preferences/preferences.service';

@Injectable({
  providedIn: 'root'
})
export class IndexeddbService {
  dbVersion = 6; // Versión inicial
  dbName = "PilotDatabase";
  tables = ["safety", "profile", "rol", "report"];

  constructor(private _preferences: PreferencesService) {
    
    
    this.initDatabase();
  }

  private async initDatabase() {
    let value = await this._preferences.getItem('dbVersion');
    this.dbVersion = value? JSON.parse(value) : this.dbVersion;
    console.log("dbVersion", this.dbVersion);
    console.log("value", value);

    let request = window.indexedDB.open(this.dbName, this.dbVersion);

    request.onupgradeneeded = (event) => {
      let db = request.result;

      // Crear las tablas si no existen
      for (const table of this.tables) {
        if (!db.objectStoreNames.contains(table)) {
          db.createObjectStore(table);
        }
      }
    };
  }

  private async ensureTableExists(table: string) {
    return new Promise<void>((resolve, reject) => {
      let request = window.indexedDB.open(this.dbName, this.dbVersion);

      request.onsuccess = (event) => {
        let db = request.result;
        if (!db.objectStoreNames.contains(table)) {
          console.warn(`Tabla ${table} no encontrada. Se actualizará la base de datos.`);
          this.upgradeDatabase(table).then(resolve).catch(reject);
        } else {
          resolve();
        }
      };

      request.onerror = () => {
        reject("Error al abrir la base de datos.");
      };
    });
  }

  private upgradeDatabase(newTable: string) {
    return new Promise<void>((resolve, reject) => {
      let newVersion = this.dbVersion + 1; // Incrementar la versión
      let request = window.indexedDB.open(this.dbName, newVersion);

      request.onupgradeneeded = (event) => {
        let db = request.result;

        // Crear la nueva tabla si no existe
        if (!db.objectStoreNames.contains(newTable)) {
          db.createObjectStore(newTable);
        }

        // Mantener las tablas previas
        for (const table of this.tables) {
          if (!db.objectStoreNames.contains(table)) {
            db.createObjectStore(table);
          }
        }

        this.dbVersion = newVersion; // Actualizar la versión en el servicio
        this._preferences.setItem("dbVersion", this.dbVersion);
        console.log(`Base de datos actualizada a versión ${newVersion}, tabla ${newTable} creada.`);
        resolve();
      };

      request.onsuccess = () => resolve();
      request.onerror = (event) => reject("Error al actualizar la base de datos.");
    });
  }

  async addToTable(table: string, data: any, key: string) {
    try {
      await this.ensureTableExists(table);
      let request = window.indexedDB.open(this.dbName, this.dbVersion);

      request.onsuccess = (event) => {
        let db = request.result;
        let transaction = db.transaction([table], "readwrite").objectStore(table);
        transaction.put(data, key);
      };
    } catch (error) {
      console.error(error);
    }
  }

  async getDataFromTable(table: string, key: string): Promise<any> {
    try {
      await this.ensureTableExists(table);
      return new Promise((resolve, reject) => {
        let request = window.indexedDB.open(this.dbName, this.dbVersion);
        request.onsuccess = (event) => {
          let db = request.result;
          let transaction = db.transaction([table], "readonly").objectStore(table);
          let data = transaction.get(key);
          data.onsuccess = (event) => resolve(data.result);
        };
      });
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async clearDatabase() {
    try {
      let request = window.indexedDB.open(this.dbName, this.dbVersion);
      request.onsuccess = async (event) => {
        let db = request.result;
        for (const table of this.tables) {
          try {
            await this.ensureTableExists(table);
            let transaction = db.transaction([table], "readwrite").objectStore(table);
            transaction.clear();
          } catch (error) {
            console.warn(`No se pudo limpiar ${table}: ${error}`);
          }
        }
      };
    } catch (error) {
      console.error(error);
    }
  }

  async deleteElementFromDatabase(table: string, key: string) {
    try {
      await this.ensureTableExists(table);
      let request = window.indexedDB.open(this.dbName, this.dbVersion);
      request.onsuccess = (event) => {
        let db = request.result;
        let transaction = db.transaction([table], "readwrite").objectStore(table);
        transaction.delete(key);
      };
    } catch (error) {
      console.error(error);
    }
  }
}
