import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  keysNotDeleteStorage = [
    'dbVersion'
  ];

  async migrate() {
    await Preferences.migrate()
  }

  async setItem(key: string, value: any) {
    await Preferences.set({ key, value: typeof value == 'string'? value : JSON.stringify(value) });
  };

  async getItem(key: string){
    const { value } = await Preferences.get({ key });
    return value;
  };
  
  async removeItem(key: string) {
    await Preferences.remove({ key });
  };

  async clearAllStorage() {
    await Preferences.clear();
    localStorage.clear();
  }

  async clearStorage() {
    let keys = await this.getKeys();
    for (const key of keys.keys) {
      if (!this.keysNotDeleteStorage.includes(key)) {
        await this.removeItem(key);
      }
    }
  }

  async getKeys() {
    return await Preferences.keys();
  }
}
