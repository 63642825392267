import { Capacitor } from '@capacitor/core';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { MARKET_IOS_APP_ID } from 'src/app/modules/pilot/commons/pilot-constants';

const COUNTRY = { country: 'US' };

export  const getCurrentAppVersion = async () => {
  const result = await AppUpdate.getAppUpdateInfo(COUNTRY);
  if (Capacitor.getPlatform() === 'android') {
    return result.currentVersionCode;
  } else {
    return result.currentVersionName;
  }
};

export const getAvailableAppVersion = async () => {
  const result = await AppUpdate.getAppUpdateInfo(COUNTRY);
  if (Capacitor.getPlatform() === 'android') {
    return result.availableVersionCode;
  } else {
    return result.availableVersionName;
  }
};

export const openAppStore = async () => {
  await AppUpdate.openAppStore({
    appId: MARKET_IOS_APP_ID
  });
};

const performImmediateUpdate = async () => {
  const result = await AppUpdate.getAppUpdateInfo(COUNTRY);
  if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
    return;
  }
  if (result.immediateUpdateAllowed) {
    await AppUpdate.performImmediateUpdate();
  }
};

export const startFlexibleUpdate = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
    return;
  }
  if (result.flexibleUpdateAllowed) {
    await AppUpdate.startFlexibleUpdate();
  }
};

const completeFlexibleUpdate = async () => {
  await AppUpdate.completeFlexibleUpdate();
};